


export enum MediatorPaths {
    createCase = '/Mediator/new-case',
    mediatorDashboard = '/Mediator/dashboard'

}
export enum AdminPaths {
    addMediator = '/Admin/add-mediator',
    adminDashboard = '/Admin/dashboard'
}


const IPS = {
    local: 'http://localhost:3000',
    production: 'https://mediation-app-frontend.vercel.app'
}

export const getIP = ()=>{
    return process.env.NODE_ENV === 'production' ? IPS.production : IPS.local
}

