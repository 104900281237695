import { ActionMsg, ActionPayloads, ActionTypes } from "types/CaseTypes";
import { useAppSelector } from "./storHook";
import {
  EditMsgType,
  GroupType,
  LikeType,
  MessageType,
} from "types/messageType";
const useMakeSocketData = () => {
  const user = useAppSelector((state) => state.user);
  const { case_id } = useAppSelector((state) => state.case);
  const displayName = user.first_name + " " + user.last_name;
  const userSide = user.side || "M";

  const makeActionMsg = (
    actionPayload: ActionPayloads,
    actionType: ActionTypes,
    extMsg?: string,
    extSide?: GroupType
  ): ActionMsg | null => {
    if (!userSide || !case_id) return null; // Return early if case_id is not defined

    return {
      time: new Date(),
      actionPayload,
      actionType,
      displayName,
      from: user.id,
      to: case_id,
      side: extSide || userSide,
      extMsg,
    };
  };

  type EndGuidType = {
    case_id: string;
  };

  const makeEndGuidMsg = (): EndGuidType | null => {
    if (!case_id) return null; // Return early if case_id is not defined
    return {
      case_id,
    };
  };

  type GuidType = {
    case_id: string;
    stageIdx?: number;
    initialIteration?: number;
    iteration?: number;
  };

  const makeGuidMsg = (
    stageIdx?: number,
    initialIteration?: number,
    iteration?: number
  ): GuidType | null => {
    if (!case_id) return null; // Return early if case_id is not defined

    return {
      case_id,
      stageIdx,
      initialIteration,
      iteration,
    };
  };

  const makeLikeMsg = (
    message_id: string,
    groupSide: GroupType,
    action: "add" | "remove"
  ): LikeType | null => {
    if (!userSide || !case_id) return null; // Return early if case_id is not defined
    return {
      message_id,
      groupSide,
      user_id: user.id,
      displayName,
      case_id,
      like: "like",
      action,
      side: userSide,
    };
  };

  const makeEditMsg = (
    selectedGroup: GroupType,
    message_id?: string,
    message?: string
  ): EditMsgType | null => {
    if (!userSide || !case_id || !message_id || !message) return null;
    return {
      message_id,
      case_id,
      side: selectedGroup,
      message,
    };
  };

  const makeRoomMsg = (
    message: string,
    selectedGroup: GroupType,
    ext?: Object
  ): MessageType | null => {
    if (!userSide || !case_id) return null; // Return early if case_id is not defined
    return {
      to: case_id,
      fromSide: userSide as GroupType,
      message,
      from: user.id,
      time: new Date(),
      side: selectedGroup,
      displayName,
      ext,
    };
  };

  return {
    makeActionMsg,
    makeLikeMsg,
    makeEditMsg,
    makeRoomMsg,
    makeGuidMsg,
    makeEndGuidMsg,
  };
};

export default useMakeSocketData;
