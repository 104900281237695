import { useSocket } from "hooks/useSocket";
import { useEffect, useMemo, useState } from "react";
import LoadingLogo from "pages/loadingPage/loadingLogo";
import { AnimatePresence } from "framer-motion";
import { coloredParticipantIcons } from "../../components/infoSection/infoSectionData";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { MessageType } from "types/messageType";
import moment from "moment";
import { DialogHeader } from "@/components/ui/dialog";
import CostumeDialog from "../../components/dialog/genericDialog";

const PAGE_COUNT = 12;
const CaseMessages = ({ case_id, messages }: { case_id?: string, messages:MessageType[] }) => {
  const [pageIdx, setPageIdx] = useState(0);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const startIdx = useMemo(() => {
    return pageIdx * PAGE_COUNT;
  }, [pageIdx]);

  const setMessageAndOpenDialog = (message: string) => {
    setDialogMessage(message);
  };

  const resetMessageDialog = () => {
    setDialogMessage("");
  };

  return (
    <>
      <AnimatePresence mode="sync">
        <LoadingLogo isLoading={!messages.length} />
      </AnimatePresence>
      <CostumeDialog
        isOpen={dialogMessage.length > 0}
        closeDialog={resetMessageDialog}
      >
        <div className="h-fit bg-white text-black p-2">
          <DialogHeader className="font-bold">Message</DialogHeader>
          <div className="p-4">{dialogMessage}</div>
        </div>
      </CostumeDialog>

      <Table className="table-auto ">
        <TableHeader className="sticky top-0 bg-white z-10 ">
          <TableHead>#</TableHead>
          <TableHead className="font-bold">Sender Side</TableHead>
          <TableHead className="font-bold">Sender ID</TableHead>
          <TableHead className="font-bold">Sender</TableHead>
          <TableHead className="font-bold">Date</TableHead>
          <TableHead className="font-bold">Time</TableHead>
          <TableHead className="font-bold">To Group</TableHead>
          <TableHead className="font-bold">Message</TableHead>
        </TableHeader>
        <TableBody>
          {messages
            .slice(pageIdx * PAGE_COUNT, (pageIdx + 1) * PAGE_COUNT)
            .map((msg, index) => {
              const date = moment(msg.time).format("DD/MM/YYYY");
              const time = moment(msg.time).format("HH:mm");

              return (
                <TableRow key={msg._id}>
                  <TableCell className="w-16 max-w-16 ">
                    {index + 1 + startIdx}
                  </TableCell>
                  <TableCell className="min-w-12 max-w-12 w-12">
                    <div className="w-8 h-8">
                      {
                        coloredParticipantIcons[
                          msg.fromSide as keyof typeof coloredParticipantIcons
                        ]
                      }
                    </div>
                  </TableCell>
                  <TableCell className="min-w-12 max-w-12 w-12">
                    {msg.from}
                  </TableCell>
                  <TableCell className="min-w-12 max-w-12 w-12">
                    {msg.displayName}
                  </TableCell>
                  <TableCell className="w-24 max-w-24 min-w-24">
                    {date}
                  </TableCell>
                  <TableCell className="min-w-12 max-w-12 w-12">
                    {time}
                  </TableCell>
                  <TableCell className="min-w-12 max-w-12 w-12">
                    <div className="w-8 h-8">
                      {
                        coloredParticipantIcons[
                          msg.side as keyof typeof coloredParticipantIcons
                        ]
                      }
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => setMessageAndOpenDialog(msg.message)}
                    className=" cursor-pointer"
                  >
                    {msg.message}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <div className="flex justify-end gap-4 p-2 ">
        <button
          onClick={() => setPageIdx((prev) => Math.max(prev - 1, 0))}
          disabled={pageIdx === 0}
          className="btn btn-primary btn-sm text-white"
        >
          Prev
        </button>
        <button
          onClick={() =>
            setPageIdx((prev) =>
              Math.min(prev + 1, Math.floor(messages.length / PAGE_COUNT))
            )
          }
          disabled={pageIdx + 1 === Math.ceil(messages.length / PAGE_COUNT)}
          className="btn btn-primary btn-sm text-white"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default CaseMessages;
