import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { motion, AnimatePresence } from "framer-motion";
import "./dropdown.css";

interface PopOverContent {
  trigger: React.ReactNode;
  children: React.ReactNode;
  className?: string;

}

const PopOver = (props: PopOverContent) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="w-fit h-fit bg-transparent hover:bg-transparent">
          {props.trigger}
        </Button>
      </PopoverTrigger>
      <AnimatePresence>
        <PopoverContent
          side="left"
          align="start"
          className={`w-fit popOverContent h-fit z-10 ${props.className}`}
          
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            {props.children}
          </motion.div>
        </PopoverContent>
      </AnimatePresence>
    </Popover>
  );
};

export default PopOver;
