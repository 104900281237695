import { useAppSelector } from "hooks/storHook";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { getRole } from "../../utils/utilsFunction";
import { useSocket } from "hooks/useSocket";
import {  ActionPayloads, ActionTypes } from "types/CaseTypes";
import { useAppDispatch } from "hooks/storHook";
import { useScroll } from "framer-motion";
import MessageBoardView from "./MessageBoardView";
import useEmitFunctions from "hooks/useEmitFunctions";
import { clearAction } from "store";
import { MessageBoardProps } from "./chatInterfaces";



const MessagesBoard = (props: MessageBoardProps) => {
  //state=======================
  const user = useAppSelector((state) => state.user);
  const messages = useAppSelector((state) => state.messages);
  const endBoardRef = useRef<HTMLDivElement>(null);
  const { actions } = useAppSelector((state) => state.chat);
  const { case_id } = useAppSelector((state) => state.case);
  const [initialState, setInitialState] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const previousScrollProgress = useRef(0);
  const [editMsgData, setEditMsgData] = useState<{
    message_id: string;
    newMessage: string;
  } | null>(null);

  //hooks=======================
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  const { scrollYProgress } = useScroll({ container: scrollRef });
  const { sendActionMsg, sendLikeMsg, sendEditMsg, sendRoomMsg } =
    useEmitFunctions();

  //vars=======================
  const role = getRole(user);
  const messageDisplay = useMemo(() => {
    return messages.filter((msg) => msg.side === props.selectedGroup);
  }, [messages, props.selectedGroup]);



  // Use useEffect instead of useMotionValueEvent to avoid re-renders
  //useEffects=======================
  useEffect(() => {
    const unsubscribe = scrollYProgress.on("change", (latest) => {
      if (previousScrollProgress.current !== latest) {
        previousScrollProgress.current = latest;
        props.handleScroll(latest);
      }
    });
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [scrollYProgress, props]);

  useEffect(() => {
    scrollRef.current?.scrollTo({behavior: "auto", top: endBoardRef.current?.offsetTop});
  }, [messageDisplay]);

  useEffect(() => {
    if (!props.searchTerm) {
      props.setSearchIndex(-1);
      props.setPotentialMessages([]);
      return;
    }
    const search = props.searchTerm.toLowerCase();
    const filteredMessages = messageDisplay.filter((msg) =>
      msg.message.toLowerCase().includes(search)
    );
    if (!filteredMessages.length) return;
    props.setPotentialMessages([...filteredMessages]);

    props.setSearchIndex(0);
  }, [props.searchTerm, messageDisplay]);

  useEffect(() => {
    if (props.searchIndex === -1) return;
    const msg = props.potentialMessages[props.searchIndex];
    const el = document.getElementById(msg._id as string);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.searchIndex]);

  useEffect(() => {
    checkInitialIncluded();
  }, [actions, role]);

  //functions=======================
  const checkInitialIncluded = useCallback(() => {
    if (actions.includes("initialPosition") && role === "User") {
      props.setActionMove(true);
    } else {
      props.setActionMove(false);
    }
  }, [actions, role]);

  const openEdit = (message_id: string, message: string) => {
    setEditMsgData({ message_id, newMessage: message });
  };

  //handlers=======================
  const handleClickAction = () =>
    sendActionMsg(ActionPayloads.initialPosition, ActionTypes.remove);

  const handleInitialStateChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInitialState(e.target.value);
  };
  const handleSendLike = (data: { msg_id: string; like: boolean }) =>
  sendLikeMsg(data.msg_id, props.selectedGroup, data.like ? "add" : "remove");

  const handleEditChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditMsgData((prev) => {
      if (!prev) return null;
      return { ...prev, newMessage: e.target.value };
    });
  };

  //ons=======================
  const onPassInitialState = () => {
    sendActionMsg(ActionPayloads.initialPosition, ActionTypes.add);
    props.setInitialStateOpened(false);
    props.setActionMove(true);
  };
  const onSubmitInitialState = useCallback(() => {
    if (!user.side || !initialState) return;
    sendRoomMsg(initialState, "M", {
      type: "guided",
      stage: "initial",
    });
    props.setInitialStateOpened(false);

    const payload =
      user.side === "A"
        ? ActionPayloads.receivedInitialA
        : ActionPayloads.receivedInitialB;

    sendActionMsg(payload, ActionTypes.add, initialState);

    dispatch(clearAction(ActionPayloads.initialPosition));
    props.setActionMove(false);
    setInitialState("");
  }, [user, initialState, case_id, socket, dispatch]);

  const onSubmitEdit = () => {
    const newMsg = editMsgData?.newMessage;
    const msg_id = editMsgData?.message_id;
    sendEditMsg(props.selectedGroup, newMsg, msg_id);
  };

  return (
    <MessageBoardView
      scrollRef={scrollRef}
      messageDisplay={messageDisplay}
      openEdit={openEdit}
      handleSendLike={handleSendLike}
      editMsgData={editMsgData}
      setEditMsgData={setEditMsgData}
      onSubmitEdit={onSubmitEdit}
      handleEditChange={handleEditChange}
      handleClickAction={handleClickAction}
      initialStateOpened={props.initialStateOpened}
      onSubmitInitialState={onSubmitInitialState}
      onPassInitialState={onPassInitialState}
      handleInitialStateChange={handleInitialStateChange}
      endBoardRef={endBoardRef}
    />
  );
};

export default MessagesBoard;
