import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./storHook";
import { GreetingsInitialMsg } from "../components/chat/GuidedNegotiation/GuidedUtils";
import {
  addInitialPosition,
  addIteration,
  changeIteration,
  setGuidedNegotiation,
  setGuidedNegotiationStage,
} from "store";
import { ActionPayloads, ActionTypes, Emits } from "types/CaseTypes";
import { GroupType } from "types/messageType";
import useEmitFunctions from "./useEmitFunctions";

const useGuidedChat = () => {
  const dispatch = useAppDispatch();
  const { stage, iteration, initialIteration, initialPosition } =
    useAppSelector((state) => state.guid);
  const [greetingsMsg, setGreetingsMsg] = useState<string>(GreetingsInitialMsg);
  const [initialReceived, setInitialReceived] = useState<{
    A: boolean;
    B: boolean;
  }>({ A: false, B: false });
  const [continueAnyWay, setContinueAnyWay] = useState<boolean>(false);

  const {
    sendGuidMsg,
    sendRoomMsg,
    sendActionMsg,
    sendEndGuidedNegotiationMsg,
  } = useEmitFunctions();

  const [initData, setInitData] = useState<{
    startWith: string;
    iterationNumber: number;
  }>({
    startWith: "A",
    iterationNumber: 2,
  });

  const handleContinueAnyway = () => {
    setContinueAnyWay(true);
  };

  const handleChangeStage = (idx: number) => {
    dispatch(setGuidedNegotiationStage(idx));
    sendGuidMsg({ stageIdx: idx });
  };

  const handleStartNegotiation = (idx: number) => {
    sendGuidMsg({ initialIteration: idx, iteration: idx });
    dispatch(setGuidedNegotiation({ isStarted: true, iteration: idx }));
    setInitialReceived({ A: false, B: false });
    dispatch(addInitialPosition({ A: "", B: "" }));
    sendRoomMsg(greetingsMsg, "M", { type: "guided", stage: "greetings" });
    const initialMsg =
      "Party A and Party B please present your initial positions.";

    setTimeout(() => {
      sendRoomMsg(initialMsg, "M", { type: "guided", stage: "initial state" });
      sendInitialPositionAction({ side: "M" });
    }, 1000);
  };

  const sendInitialPositionAction = ({ side }: { side: string }) => {
    sendActionMsg(
      ActionPayloads.initialPosition,
      ActionTypes.add,
      "",
      side as GroupType
    );
  };

  useEffect(() => {
    if (!initialPosition?.A && !initialPosition?.B && !continueAnyWay) return;
    if (stage !== 3) return;
    const receiveA = initialPosition?.A !== "";
    const receiveB = initialPosition?.B !== "";
    setInitialReceived({ A: receiveA, B: receiveB });

    if ((receiveA && receiveB) || continueAnyWay) {
      setTimeout(() => {
        handleChangeStage(4);
      }, 1500);
      sendStartNegotiationMsg(initialIteration);
      sendGuidMsg({ initialIteration });
    }
  }, [initialPosition, continueAnyWay]);

  const sendStartNegotiationMsg = (idx: number) => {
    const message = `
    Party A and Party B, thank you very much.
    You have ${initData.iterationNumber} interactions for this session.
    party ${initData.startWith} begins.`;
    setTimeout(() => {
      sendRoomMsg(message, "M", { type: "guided", stage: "initial" });

      setTimeout(() => {
        sendIterationMsg({ initialNumber: idx, iteration: idx });
      }, 1000);
    }, 1200);
  };

  const sendIterationMsg = ({
    initialNumber,
    iteration,
  }: {
    initialNumber: number;
    iteration: number;
  }) => {
    if (iteration === 0) return;
    let iter = initialNumber - iteration + 1;

    let iterMsg: string = `Iteration ${iter} / ${initialNumber}`;
    sendRoomMsg(iterMsg, "M", { type: "guided", stage: "iteration" });
  };

  const handleNextIteration = () => {
    sendIterationMsg({
      initialNumber: initialIteration,
      iteration: iteration - 1,
    });
    dispatch(changeIteration(-1));
    sendGuidMsg({ iteration: iteration - 1 });
  };

  const handleEndNegotiation = () => {
    sendGuidMsg({ stageIdx: 0, initialIteration: 0, iteration: 0 });
    dispatch(setGuidedNegotiation({ isStarted: false, iteration: 0 }));
    setInitialReceived({ A: false, B: false });
    setContinueAnyWay(false);
    sendEndGuidedNegotiationMsg();
  };

  const handleAddIteration = (num: number) => {
    const message = `Party A and Party B, thank you very much. You have added ${num} more interactions for this session. party ${initData.startWith} begins.`;
    sendRoomMsg(message, "M", { type: "guided", stage: "Iteration Increase" });
    sendGuidMsg({ initialIteration: initialIteration + num });
    dispatch(addIteration(num));
    dispatch(changeIteration(num));
  };

  const onGreetingsChange = (msg: string) => {
    setGreetingsMsg(msg);
  };

  const onInitChange = (
    data: Partial<{
      startWith: string;
      iterationNumber: number;
    }>,
    name: "startWith" | "iterationNumber"
  ) => {
    setInitData((prev) => ({
      ...prev,
      [name]: data,
    }));
  };

  return {
    stage,
    iteration,
    initialIteration,
    initialPosition,
    greetingsMsg,
    initialReceived,
    continueAnyWay,
    initData,
    handleContinueAnyway,
    handleChangeStage,
    handleStartNegotiation,
    sendInitialPositionAction,
    sendStartNegotiationMsg,
    sendIterationMsg,
    handleNextIteration,
    handleEndNegotiation,
    handleAddIteration,
    onGreetingsChange,
    onInitChange,
  };
};
export default useGuidedChat;
