import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";

interface DialogContentProps {
  trigger?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  dialogHeader?: string;
  dialogDescription?: string;
  dialogAction?: React.ReactNode;
  isOpen?: boolean;
  closeDialog?: () => void;
  withTrigger?: boolean;
}

const CostumeDialog = (props: DialogContentProps) => {
  const { withTrigger = true, ...rest } = props;

  return (
    <Dialog
      onOpenChange={(isOpen) => {
        if (!isOpen && props.closeDialog) {
          props.closeDialog(); // Call closeDialog when the dialog is being closed
        }
      }}
      open={props.isOpen}
    >
      {withTrigger && (
        <DialogTrigger asChild>
          <Button className="w-fit h-fit bg-transparent hover:bg-transparent">
            {props.trigger}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className={`w-full top-56 p-1 bg-transparent rounded-sm m-0 ${props.className}`}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default CostumeDialog;
