import AdminNavBar from "../../components/navBar/AdminNavBar";
import Headline from "../../components/background/headline";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AddMediator from "./AddMediatorStage";
import ManageAccountStage from "./ManageAccountStage";
import MenuLinks from "../../components/menu/menuLinks";

const AdminPage = () => {
  const [menuPageIdx, setMenuPageIdx] = useState<number>(-1);

  return (
    <>
      <AdminNavBar />
      <section className="w-full  flex flex-col h-[100dvh] overflow-auto bg-[#414a4f] px-4 py-12 text-slate-50">
        <Headline text="Welcome Admin." />
        <AnimatePresence mode="popLayout">
          <MenuLinks
            links={[
              "Create new Mediator",
              "Manage Accounts",
              "Statistics",
              "Online Cases",
            ]}
            menuPageIdx={menuPageIdx}
            setMenuPageIdx={setMenuPageIdx}
          />

          {menuPageIdx === 0 && (
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
            >
              <AddMediator goBack={() => setMenuPageIdx(-1)} />
            </motion.div>
          )}

          {menuPageIdx === 1 && (
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
            >
              <ManageAccountStage />
            </motion.div>
          )}
          
        </AnimatePresence>
      </section>
    </>
  );
};
export default AdminPage;
