import { getIP } from "../../utils/utilsData";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { DialogClose } from "@radix-ui/react-dialog";
import { Label } from "@radix-ui/react-label";
import { PopoverClose } from "@radix-ui/react-popover";
import { useAppSelector } from "hooks/storHook";
import useMobile from "hooks/useMobile";
import { useRef } from "react";
import { useSendCaseInviteViaEmailMutation } from "store/apis/sessionApi";

const ShareCase = () => {
  const closeRef = useRef<any>(null);
  const caseData = useAppSelector((state) => state.case);
  const user = useAppSelector((state) => state.user);
  const [sendCaseInviteViaEmail] = useSendCaseInviteViaEmailMutation();
  const isMobile = useMobile();
  const { toast } = useToast();

  const handleSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.currentTarget.email.value;
    if (!email || !caseData.link || !user.email) return;
    closeRef.current?.click();
    // const IP = getIP();
    // const textLink = `${IP}/client-login?case_code=${caseData.link}`;
    const textLink = `https://beartech.info/client-login?case_code=${caseData.link}`;
    sendCaseInviteViaEmail({
      link: textLink,
      email,
      sender_email: user.email,
    });
  };

  const handleCopyLink = () => {
    // const IP = getIP();
    // const textLink = `${IP}/client-login?case_code=${caseData.link}`;
    const textLink = `https://beartech.info/client-login?case_code=${caseData.link}`;
  
    navigator.clipboard.writeText(textLink);
    closeRef.current?.click();
    toast({
      title: "Link copied to clipboard",
      className: "bg-slate-950 text-white",
      duration: 1000,
    });
  };

  return (
    <form
      onSubmit={handleSubmission}
      className="grid gap-4 w-full h-full bg-slate-900 text-slate-300 p-1 "
    >
      <div className="space-y-2">
        <h4 className="font-medium leading-none text-white">Share:</h4>
        <p className="text-sm ">
          Click{" "}
          <span className=" link text-sky-400" onClick={handleCopyLink}>
            here
          </span>{" "}
          to copy the link to this case
        </p>
      </div>
      <p className="text-sm ">Or send via email</p>
      <div className="grid gap-2 py-1">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="email">Email:</Label>
          <Input
            id="email"
            type="email"
            className="col-span-2 h-8 text-black"
          />

          <Button className="bg-slate-500 text-white h-8 col-span-1">
            Send
          </Button>
          {isMobile ? (
            <DialogClose ref={closeRef} className=" hidden" />
          ) : (
            <PopoverClose ref={closeRef} tabIndex={0} className=" hidden" />
          )}
        </div>
      </div>
    </form>
  );
};

export default ShareCase;
