import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { CaseReturnType } from "types/CaseTypes";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback } from "react";

interface CaseStatisticsProps {
  data: CaseReturnType | undefined;
  isLoading: boolean;
}

const CaseStatistics = ({ data, isLoading }: CaseStatisticsProps) => {
  return (
    <>

      <div className="grid gap-2 w-full h-fit min-h-full grid-cols-1 md:grid-cols-2 grid-auto-flow-row ">
        <div className="w-full ">
          <motion.div
            className="z-[2]"
            initial={{ opacity: 0, y: 50 }}
            animate={!isLoading && { opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, y: 50 }}
          >
            <Table className=" opacity-75 z- text-slate-400  rounded-sm  bg-slate-900  ">
              <TableCaption>Basic case Information</TableCaption>
              <TableBody>
                {data &&
                  Object.entries(data as CaseReturnType).map(([key, value]) => {
                    return (
                      <TableRow className="hover:text-slate-100 " key={key}>
                        <TableCell className="hover:animate-pulse">
                          {key}:
                        </TableCell>

                        <TableCell className="bg-slate-950 text-white">
                          {String(value || "")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </motion.div>
        </div>
        <div className=" h-full w-full mb-2 ">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={!isLoading && { opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            exit={{ opacity: 0, x: 100 }}
            className="stats stats-vertical shadow z-[0]"
          >
            <div className="stat stats-horizontal">
              <div className="stat">
                <div className="stat-title">Messages</div>
                <div className="stat-value">31K</div>
                <div className="stat-desc">
                  Total number of messages sended{" "}
                </div>
              </div>
              <div className="stat">
                <div className="stat-title">Messages private </div>
                <div className="stat-value">4,200</div>
                <div className="stat-desc">↗︎ 400 (22%)</div>
              </div>
            </div>

            <div className="stat stats-horizontal">
              <div className="stat">
                <div className="stat-title">Messages</div>
                <div className="stat-value">31K</div>
                <div className="stat-desc">
                  Total number of messages sended{" "}
                </div>
              </div>
              <div className="stat">
                <div className="stat-title">Messages private </div>
                <div className="stat-value">4,200</div>
                <div className="stat-desc">↗︎ 400 (22%)</div>
              </div>
            </div>
            
            <div className="stat">
              <div className="stat-title">Messages</div>
              <div className="stat-value">31K</div>
              <div className="stat-desc">Total number of messages sended </div>
            </div>
            <div className="stat">
              <div className="stat-title">Messages</div>
              <div className="stat-value">31K</div>
              <div className="stat-desc">Total number of messages sended </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default CaseStatistics;
