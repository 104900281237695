import LinkMenu from "../../components/lists/LinkMenu";
import { Button } from "@/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { FaChevronLeft } from "react-icons/fa";

interface MenuLinksProps {
  menuPageIdx: number;
  setMenuPageIdx: (idx: number) => void;
  links?: string[];
}

const MenuLinks = ({ menuPageIdx, setMenuPageIdx, links }: MenuLinksProps) => {
  return (
    <>
      {menuPageIdx < 0 ? (
        <motion.div
          key={1}
          exit={{
            x: -100,
            opacity: 0,
            transition: { duration: 0.4, ease: "backInOut" },
          }}
        >
          <LinkMenu
            text={links ?? []}
            onClick={(idx) => setMenuPageIdx( idx)}
          />
        </motion.div>
      ) : (
        <motion.div
          key={2}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{
            x: -100,
            opacity: 0,
            transition: { duration: 0.4, ease: "backInOut" },
          }}
          className="mx-auto w-full z-[1000]   cursor-pointer   max-w-3xl"
        >
          <Button
            variant="ghost"
            onClick={() => setMenuPageIdx( -1)}
            className="hover:bg-transparent  hover:rounded-none hover:backdrop-brightness-150   "
          >
            <FaChevronLeft className="text-3xl text-black w-8 h-8" />
          </Button>
        </motion.div>
      )}
    </>
  );
};

export default MenuLinks;
