import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/storHook";
import { useLocation, useNavigate } from "react-router-dom";
import { Emits } from "../../types/CaseTypes";
import { MessageType, GroupType } from "types/messageType";
import { getRole } from "../../utils/utilsFunction";
import ChatViewNew from "./ChatViewNew";
import { useGetCaseByIdQuery } from "store/apis/caseApi";
import {
  addNotification,
  clearCase,
  clearNotification,
  clearUser,
  setCase,
  setNotes,
  setShuttle,
} from "store";
import { useAppDispatch } from "hooks/storHook";
import { Participant } from "types/userTypes";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import useChat from "hooks/useChat";
import { toast } from "@/components/ui/use-toast";
import { Toast, ToastAction } from "@radix-ui/react-toast";
import CostumeDialog from "../../components/dialog/genericDialog";
import { set } from "zod";
import { Textarea } from "@/components/ui/textarea";
import PopButton from "../../components/buttons/PopButton";

type caseData = {
  id: string;
  creator: string;
  shuttle: "false" | "true";
  participants: Participant[];
  notes: string;
};

const ChatPage = () => {
  //state=======================
  const case_id = useLocation().state?.case_id || null;
  const [selectedGroup, setSelectedGroup] = useState<GroupType>("M");
  const [activeInfoIdx, setActiveInfoIdx] = useState(0);
  const [chatEnd, setChatEnd] = useState<boolean>(false);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const { actions } = useAppSelector((state) => state.chat);
  const [rating, setRating] = useState<number>(3);
  const [surveyNotes, setSurveyNotes] = useState<string>("");

  const { shuttle: isShuttle } = useAppSelector((state) => state.chat);
  const [participants, setParticipants] = useState<Map<string, Participant>>(
    new Map()
  );
  const surveyRatingLabels = [
    "Very dissatisfied",
    "Dissatisfied",
    "OK",
    "Satisfied",
    "Very satisfied",
  ];

  //hooks=======================
  const { on, ons, offAllListeners, socket } = useChat({
    selectedGroup,
    case_id,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //selectors===================
  const caseData = useAppSelector((state) => state.case);
  const user = useAppSelector((state) => state.user);
  const messages = useAppSelector((state) => state.messages);

  //functions===================
  const role = getRole(user);

  //api=========================
  const { data: caseInfoData, error: errorCaseInfoData } =
    useGetCaseByIdQuery(case_id);

  //useEffect===================
  useEffect(() => {
    if (!socket) return;
    if (!case_id) return;

    if (role === "Mediator") {
      on({ [Emits.startCase]: handleStartChatMediator });
      socket.emit(Emits.startCase, { case_id: case_id, mediator_id: user.id });
    }
    socket.emit(Emits.history, { case_id: case_id });
    ons([
      {
        [Emits.userJoin]: handleJoin,
        [Emits.userLeave]: handleLeave,
        [Emits.endCase]: endCase,
      },
    ]);

    return () => offAllListeners();
  }, [socket, case_id]); // Include `selectedGroup` here

  //this use effect set the case data to the store
  useEffect(() => {
    if (errorCaseInfoData) {
      const httpError = errorCaseInfoData as FetchBaseQueryError;
      if (httpError.status === 401) {
        dispatch(clearUser());
        navigate("/login");
      }
    }
    if (!caseInfoData) return;

    dispatch(
      setCase({
        link: case_id.toString().slice(-6),
        case_id: caseInfoData.id,
        category: caseInfoData.category,
        problem_brief: caseInfoData.problem_brief,
        sideA_description: caseInfoData.sideA_description,
        sideB_description: caseInfoData.sideB_description,
        sub_category: caseInfoData.sub_category,
      })
    );
  }, [caseInfoData, errorCaseInfoData]);

  useEffect(() => {
    if (actions.includes("fillSurvey")) {
      toast({
        title: "Please fill out the survey",
        description: "click on the button to fill out the survey",
        className: "bg-slate-950 text-white",
        duration: Infinity,
        action: (
          <ToastAction
            onClick={() => setDialogOpened(true)}
            altText={"fill survey button"}
            className="bg-sky-300 text-black rounded-sm font-mono"
          >
            Fill Survey
          </ToastAction>
        ),
      });
    }
  }, [actions]);

  useEffect(() => {
    if (role !== "User") return;
    caseData.participants.forEach((p) => {
      setParticipants((prev) => {
        const updatedMap = new Map(prev); // Create a new copy of the Map
        updatedMap.set(p.user_id, p); // Add the participant
        return updatedMap; // Return the new Map
      });
    });
    dispatch(setShuttle(caseData.shuttle));
  }, [caseData]);

  //handlers===================
  const handleStartChatMediator = (res: caseData) => {
    dispatch(setShuttle(res.shuttle === "true"));
    const participantsMap = new Map<string, Participant>();
    const participantsArray = res.participants;
    participantsArray?.forEach((p) => {
      participantsMap.set(p.user_id, p);
    });
    setParticipants(() => participantsMap);

    if (!res?.notes?.length) return;
    const notes = JSON.parse(res.notes);
    dispatch(setNotes([...notes]));
  };

  const handleJoin = (data: Participant) => {
    setParticipants((prev) => {
      const updatedMap = new Map(prev); // Create a new copy of the Map
      updatedMap.set(data.user_id, data); // Add the participant
      return updatedMap; // Return the new Map
    });
    dispatch(addNotification(`${data.displayName} joined to chat`));
  };

  const handleLeave = (data: Participant) => {
    setParticipants((prev) => {
      const updatedMap = new Map(prev); // Create a new copy of the Map
      updatedMap.delete(data.user_id); // Remove the participant
      return updatedMap; // Return the new Map
    });
    dispatch(addNotification(`${data.displayName} left the chat`));
  };

  const handleFillSurvey = () => {};

  const handleShuttle = () => {
    if (role !== "Mediator") return;
    const shuttleState = !isShuttle;
    // setIsShuttle(() => shuttleState);
    socket?.emit(Emits.shuttle, {
      case_id,
      to: "M",
      mediator_id: user.id,
      shuttle: shuttleState,
    });
  };

  const handleSendMessage = (message: string) => {
    const msg: MessageType = {
      to: case_id,
      fromSide: (user.side as GroupType) || "M",
      message,
      from: user.id,
      time: new Date(),
      side: selectedGroup,
      displayName: user.first_name + " " + user.last_name,
    };
    socket?.emit(Emits.roomMsg, msg);
  };

  const handleChangeGroup = (group: GroupType) => {
    dispatch(clearNotification(group)); // Clear notification for the selected group
    setSelectedGroup(group); // This will trigger re-render and re-run the useEffect
  };

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleSelectInfo = (idx: number) => {
    setActiveInfoIdx(idx);
  };

  const handleSendSurvey = () => {
    toast({
      title: "Survey Action Sent",
      className: "bg-slate-950 text-white",
      duration: 1500,
    });
    socket?.emit(Emits.sendSurvey, { case_id });
  };

  //functions===================
  const endCase = async (data: any) => {
    setChatEnd(true);
  };

  const onClosedCase = (accept: boolean) => {
    if (accept) {
      dispatch(clearCase());
      localStorage.removeItem("case_id");
      navigate("/login");
    }
  };

  //view=======================

  return (
    <>
      <div className="absolute">
        <CostumeDialog
          className="bg-slate-200"
          closeDialog={() => setDialogOpened(false)}
          isOpen={dialogOpened}
        >
          <div className="flex flex-col gap-3">
            <h1 className="text-3xl text-center">Survey</h1>
            <p>How mach you satisfied with this case resolve</p>
            <div className="rating">
              {[1, 2, 3, 4, 5].map((value) => (
                <input
                  key={value}
                  type="radio"
                  name="rating"
                  className="mask mask-star-2 bg-orange-400 transition-colors duration-300"
                  checked={rating === value} // Checks the input if rating matches
                  onChange={() => handleRatingChange(value)} // Updates the state when clicked
                />
              ))}
              <p className="ml-4"> {surveyRatingLabels[rating - 1]}</p>
            </div>
            <div className="flex flex-col gap-2">
              <Textarea
                placeholder="Please write your feedback"
                className="w-full max-h-44"
                onChange={(e) => setSurveyNotes(e.target.value)}
              />
              <PopButton
                color="bg-sky-500"
                handleClick={() => console.log("submit")}
                text="Submit"
              />
            </div>
          </div>
        </CostumeDialog>
      </div>
      <ChatViewNew
        handleSendSurvey={handleSendSurvey}
        onDialogClose={onClosedCase}
        openedDialog={chatEnd}
        selectedInfo={activeInfoIdx}
        handleSelectInfo={handleSelectInfo}
        caseInfo={caseInfoData}
        role={role}
        isShuttle={isShuttle}
        case_id={case_id}
        selectedGroup={selectedGroup}
        user={user}
        messages={messages}
        handleSendMessage={handleSendMessage}
        onChangeGroup={handleChangeGroup}
        handleShuttle={handleShuttle}
        participants={participants}
      />
    </>
  );
};

export default ChatPage;
