import { CaseReturnType } from "types/CaseTypes";
import InfoCard from "../card/infoCard";
import { motion } from "framer-motion";
import PopButton from "../../components/buttons/PopButton";

interface CaseInfoProps {
  caseInfo?: CaseReturnType;
  handleSendSurvey: () => void;
  role: string;
}

const CaseInfo = (props: CaseInfoProps) => {
  return (
    <motion.header
      initial={{ x: "-10%", opacity: 0 }}
      animate={{ x: "0%", opacity: 1 }}
      exit={{ x: "10%" }}
      transition={{
        duration: 0.3,
        delay: 0.3,
        ease: "backOut",
      }}
      className="px-2 flex flex-col relative overflow-x-hidden"
    >
      <h3 className="text-white text-lg font-mono font-extralight text-center">
        Case Info
      </h3>

      <InfoCard caseData={props.caseInfo} />
      {props.role === "Mediator" && (
        <div className="flex justify-center ">
          <PopButton
            handleClick={props.handleSendSurvey}
            color="bg-slate-300"
            text={"send survey"}
          ></PopButton>
        </div>
      )}
    </motion.header>
  );
};
export default CaseInfo;



const InfoStatistics = () => {


  return(
    <></>
  )


}
