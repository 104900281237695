import { AnimatePresence, motion } from "framer-motion";
import { Logo } from "pages/login/LoginPageView";


 const LoadingLogo = ({ isLoading }: { isLoading?: boolean }) => {
    if (!isLoading) return <></>;
  
    return (
      <AnimatePresence mode="sync">
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0.6, duration: 0.5 } }}
          className=" fixed  left-[50%] top-[50%] z-50  translate-x-[-50%] translate-y-[-50%]"
        >
          {Logo}
          <h1 className="text-xl mt-5">
            Loading{" "}
            <span className="loading align-bottom loading-dots loading-sm text-white"></span>
          </h1>
        </motion.div>
      </AnimatePresence>
    );
  };
  export default LoadingLogo