import {
  TbCircleLetterAFilled,
  TbCircleLetterBFilled,
  TbCircleLetterMFilled,
} from "react-icons/tb";

export const participantIcons = {
  M: <TbCircleLetterMFilled color="white" className="w-full h-full" />,
  A: <TbCircleLetterAFilled color="white" className="w-full h-full" />,
  B: <TbCircleLetterBFilled color="white" className="w-full h-full" />,
};

export const coloredParticipantIcons = {
  M: (
    <div className="w-full h-full rounded-full bg-black">
      <TbCircleLetterMFilled className={`w-full h-full text-violet-600`} />
    </div>
  ),
  A: (
    <div className="w-full h-full rounded-full bg-black">
      <TbCircleLetterAFilled className={`w-full h-full text-teal-600`} />
    </div>
  ),
  B: (
    <div className="w-full h-full rounded-full bg-black">
      <TbCircleLetterBFilled className={`w-full h-full text-rose-600`} />
    </div>
  ),
};

export const participantColors = {
  M: "bg-violet-600",
  A: "bg-teal-600",
  B: "bg-rose-600",
};
