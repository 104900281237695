import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useGetMediatorsQuery } from "store/apis/adminApi";
import { MdOutlineCheck, MdClose } from "react-icons/md";

const ManageAccountStage = () => {
  const { data, error, isLoading } = useGetMediatorsQuery(null);

  console.log(data, error, isLoading);

  return (
    <div className="mx-auto w-full  mt-12 max-w-3xl pb-12 p-2 ">
      <Table className="border-2 border-white">
        <TableCaption>Mediators list</TableCaption>
        <TableHeader >
          <TableRow className="bg-slate-900">
            <TableHead className="text-white">FULL NAME</TableHead>
            <TableHead className="text-white">EMAIL</TableHead>
            <TableHead className="text-white">PHONE</TableHead>
            <TableHead className="text-white">ADDRESS</TableHead>
            <TableHead className="text-white">CERTIFICATION</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className=" backdrop-brightness-125  overflow-y-scroll">
          {data?.map((mediator: any) => (
            <TableRow  key={mediator.user.id}>
              <TableHead className="text-black" >
                {mediator.user.first_name +" "+ mediator.user.last_name}
              </TableHead>
              <TableHead className="text-black" >{mediator.user.email}</TableHead>
              <TableHead className="text-black" >{mediator.phone}</TableHead>
              <TableHead className="text-black" >{mediator.address}</TableHead>
              <TableHead className="text-black" > 
                {mediator.certification_course ? (
                  <MdOutlineCheck className="w-4 h-4" />
                ) : (
                  <MdClose className="w-4 h-4" />
                )}
                
              </TableHead>
            </TableRow>
          ))}
           {data?.map((mediator: any) => (
            <TableRow  key={mediator.user.id}>
              <TableHead className="text-black" >
                {mediator.user.first_name +" "+ mediator.user.last_name}
              </TableHead>
              <TableHead className="text-black" >{mediator.user.email}</TableHead>
              <TableHead className="text-black" >{mediator.phone}</TableHead>
              <TableHead className="text-black" >{mediator.address}</TableHead>
              <TableHead className="text-black" > 
                {mediator.certification_course ? (
                  <MdOutlineCheck className="w-4 h-4" />
                ) : (
                  <MdClose className="w-4 h-4" />
                )}
                
              </TableHead>
            </TableRow>
          ))}
          
          
        </TableBody>
      </Table>
    </div>
  );
};
export default ManageAccountStage;
