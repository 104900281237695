import { useAppSelector } from "hooks/storHook";
import Message from "./Message";
import BottomDrawer from "../../components/drawer/bottomDrawer";
import PopButton from "../../components/buttons/PopButton";
import { Textarea } from "@/components/ui/textarea";
import { MessageBoardViewProps } from "./chatInterfaces";


const MessageBoardView = (props: MessageBoardViewProps) => {
  const likes = useAppSelector((state) => state.like);
  const user = useAppSelector((state) => state.user);
  
  return (
    <div
      ref={props.scrollRef}
      className="w-full relative h-full px-2 overflow-y-scroll snap-y snap-mandatory overflow-x-hidden py-2"
    >
      {props.messageDisplay.map((msg, i) => {
        const likeData = likes[msg._id as string];
        const likeA = likeData?.likeData.A.length || 0;
        const likeB = likeData?.likeData.B.length || 0;
        const likeM = likeData?.likeData.M.length || 0;
        const isMeLike = likeData?.isMe || false;
        const isMe = msg.from === user.id;

        return (
          <Message
            editMessage={props.openEdit}
            likeA={likeA}
            likeB={likeB}
            likeM={likeM}
            ILiked={isMeLike}
            handleSendLike={props.handleSendLike}
            ext={msg.ext}
            side={msg.fromSide || "M"}
            key={msg._id || i}
            msg={msg}
            isMe={isMe}
          />
        );
      })}

      <BottomDrawer
        open={!!props.editMsgData}
        onOpenChange={(open) => {
          if (!open) props.setEditMsgData(null);
        }}
        drawerTitle="Edit Message"
        drawerFooter={
          <div className="flex justify-center items-center gap-4">
            <PopButton text="Submit" handleClick={props.onSubmitEdit} />
          </div>
        }
      >
        <div className="w-full h-full flex justify-center items-center">
          <Textarea
            onChange={props.handleEditChange}
            value={props.editMsgData?.newMessage}
            className="bg-transparent border-none outline-none w-1/2 ring-[0.3px] text-white focus-visible:ring-[0.3px] focus-visible:ring-offset-0 h-44 text-lg font-thin rounded-none resize-none"
          />
        </div>
      </BottomDrawer>

      <BottomDrawer
        onOpenChange={(open) => open && props.handleClickAction()}
        open={props.initialStateOpened}
        drawerTitle="Initial State"
        drawerFooter={
          <div className="flex justify-center items-center gap-4">
            <PopButton text="Submit" handleClick={props.onSubmitInitialState} />
            <PopButton
              text="Pass to group"
              color="bg-rose-300"
              handleClick={props.onPassInitialState}
            />
          </div>
        }
      >
        <div className="w-full h-full flex justify-center items-center">
          <Textarea
            onChange={props.handleInitialStateChange}
            placeholder="Write your initial state here"
            className="bg-transparent border-none outline-none w-1/2 ring-[0.3px] text-white focus-visible:ring-[0.3px] focus-visible:ring-offset-0 h-44 text-lg font-thin rounded-none resize-none"
          />
        </div>
      </BottomDrawer>

      <div ref={props.endBoardRef} id="end-board"></div>
    </div>
  );
};
export default MessageBoardView;
