import Headline from "../../components/background/headline";
import { useLocation } from "react-router-dom";
import { useGetCaseByIdQuery } from "store/apis/caseApi";
import { Emits } from "types/CaseTypes";
import { AnimatePresence, motion } from "framer-motion";
import BubbleBackground from "../../components/background/BubbleBackground";
import useChatSocket from "hooks/useChatSocket";
import { useEffect, useMemo, useState } from "react";
import { MessageType } from "types/messageType";
import CaseStatistics from "./CaseStatistics";
import Tabs from "../../components/listTab/tabs";
import CaseMessages from "./CaseMessages";
import LoadingLogo from "pages/loadingPage/loadingLogo";
import NavBar from "components/navBar/navBar";

const TABS = ["Statistics", "Messages"];

const InfoPage = () => {
  const location = useLocation();
  const { socket, on, offAllListeners } = useChatSocket();
  const [selected, setSelected] = useState<string>("Statistics");

  const lastPathSegment = location.pathname.split("/").pop();
  const { data, isLoading } = useGetCaseByIdQuery(lastPathSegment as string);
  const [messages, setMessages] = useState<MessageType[]>([]);

  const messagesData = useMemo(() => {
    const messagesCounter = {
      total: messages.length,
      A: 0,
      B: 0,
      M: 0,
    };
    messages.forEach((msg) => {});
  }, [messages]);

  useEffect(() => {
    if (!socket) return;
    if (!data?.id) return;
    socket.emit(Emits.history, { case_id: data.id });

    on({
      [Emits.history]: (data) => {
        setMessages(data);
      },
      [Emits.like]: (data) => {},
    });

    return () => {
      setMessages([]);
      offAllListeners();
    };
  }, [socket, data]);

  return (
    <>
      <NavBar />

      <section className="w-full flex flex-col h-[100dvh] overflow-auto bg-[#414a4f] px-4 py-12 text-slate-50">
        <Headline text="Case Info." />

        {isLoading && (
          <div className="absolute left-0 right-0 top-0 bottom-0">
            <BubbleBackground></BubbleBackground>
          </div>
        )}

        <LoadingLogo isLoading={isLoading} />

        <div className="w-full h-12">
          <Tabs tabsMap={TABS} selected={selected} setSelected={setSelected} />
        </div>

        <AnimatePresence mode="wait">
          {selected === "Statistics" ? (
            <CaseStatistics key={1} data={data} isLoading={isLoading} />
          ) : (
            <CaseMessages key={2} case_id={data?.id} messages={messages} />
          )}
        </AnimatePresence>
      </section>
    </>
  );
};
export default InfoPage;
