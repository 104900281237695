import { useSocket } from "./useSocket";
import useMakeSocketData from "./useMakeSocketData";
import { ActionPayloads, ActionTypes, Emits } from "types/CaseTypes";
import { useCallback } from "react";
import { GroupType } from "types/messageType";
const useEmitFunctions = () => {
  const { socket } = useSocket();
  const {
    makeActionMsg,
    makeLikeMsg,
    makeEditMsg,
    makeRoomMsg,
    makeGuidMsg,
    makeEndGuidMsg,
  } = useMakeSocketData();

  const sendActionMsg = useCallback(
    (
      actionPayload: ActionPayloads,
      type: ActionTypes,
      ext?: string,
      extSide?: GroupType
    ) => {
      if (!socket) throw new Error("Socket is not defined");
      const action = makeActionMsg(actionPayload, type, ext);
      if (!action) return; // Early return if the action could not be created
      socket.emit(Emits.action, action, extSide);
    },
    [makeActionMsg, socket]
  );

  const sendLikeMsg = useCallback(
    (
      message_id: string,
      selectedGroup: GroupType,
      action: "add" | "remove"
    ) => {
      if (!socket) throw new Error("Socket is not defined");
      const like = makeLikeMsg(message_id, selectedGroup, action);
      if (!like) return; // Early return if like could not be created
      socket.emit(Emits.like, like);
    },
    [makeLikeMsg, socket]
  );

  const sendEditMsg = useCallback(
    (selectedGroup: GroupType, message?: string, message_id?: string) => {
      if (!socket) throw new Error("Socket is not defined");
      const edit = makeEditMsg(selectedGroup, message_id, message);
      if (!edit) return; // Early return if edit could not be created
      socket.emit(Emits.editMsg, edit);
    },
    [makeEditMsg, socket]
  );

  const sendRoomMsg = useCallback(
    (message: string, selectedGroup: GroupType, ext?: Object) => {
      if (!socket) throw new Error("Socket is not defined");
      const roomMsg = makeRoomMsg(message, selectedGroup, ext);
      if (!roomMsg) return; // Early return if roomMsg could not be created
      socket.emit(Emits.roomMsg, roomMsg);
    },
    [makeRoomMsg, socket]
  );

  const sendGuidMsg = useCallback(
    ({
      stageIdx,
      initialIteration,
      iteration,
    }: {
      stageIdx?: number;
      initialIteration?: number;
      iteration?: number;
    }) => {
      const guidMsg = makeGuidMsg(stageIdx, initialIteration, iteration);
      if (!guidMsg) return;

      socket?.emit(Emits.guid, guidMsg);
    },
    [makeEndGuidMsg,socket]
  );

  const sendEndGuidedNegotiationMsg = useCallback(() => {
    const endGuidMsg = makeEndGuidMsg();
    if (!endGuidMsg) return;
    socket?.emit(Emits.guid, endGuidMsg);
  }, [makeEndGuidMsg, socket]);

  return {
    sendActionMsg,
    sendLikeMsg,
    sendEditMsg,
    sendRoomMsg,
    sendGuidMsg,
    sendEndGuidedNegotiationMsg,
  };
};

export default useEmitFunctions;
