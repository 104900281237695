import { memo } from "react";
import { GroupType, MessageType } from "types/messageType";
import {
  TbCircleLetterAFilled,
  TbCircleLetterBFilled,
  TbCircleLetterMFilled,
} from "react-icons/tb";
import useMobile from "hooks/useMobile";
import { SlLike, SlDislike } from "react-icons/sl";
import { CiEdit } from "react-icons/ci";
import { BiDotsVertical } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import { CiHeart } from "react-icons/ci";
import { AnimatePresence, motion } from "framer-motion";

interface MessageProps {
  msg: MessageType;
  isMe: boolean;
  side: GroupType;
  ext?: any;
  handleSendLike: (data: { msg_id: string; like: boolean }) => void;
  likeA?: number;
  likeB?: number;
  likeM?: number;
  ILiked?: boolean;
  editMessage?: (message_id: string, message: string) => void;
}

const Message = memo((props: MessageProps) => {
  const time = new Date(props.msg.time);
  const isMobile = useMobile();
  const sideColor =
    props.side === "A"
      ? "from-teal-600 to-emerald-600 bg-teal-600 "
      : props.side === "B"
      ? "from-pink-600 to-rose-600 bg-pink-600"
      : "from-indigo-600 to-violet-600 bg-indigo-600";

  const participantIcons = {
    M: <TbCircleLetterMFilled color="white" className="w-full h-full" />,
    A: <TbCircleLetterAFilled color="white" className="w-full h-full" />,
    B: <TbCircleLetterBFilled color="white" className="w-full h-full" />,
  };

  const sendLike = (like: boolean) => {
    props.handleSendLike({ msg_id: props.msg._id || "", like });
  };

  const onOpenEdit = ()=>{
    if(props.editMessage){
      props.editMessage(props.msg._id || "", props.msg.message);
    }
  }

  const guidColors =
    props.side === "B"
      ? "ring-rose-800"
      : props.side === "A"
      ? "ring-teal-800"
      : "ring-violet-800";

  const textGuidedColors =
    props.side === "B"
      ? "text-rose-500"
      : props.side === "A"
      ? "text-teal-500"
      : "text-violet-500";

  if (props.ext?.type === "guided" && props.ext.stage === "iteration") {
    return (
      <div id={props.msg._id} className="w-full h-8  relative flex items-center shadow-md  justify-center ">
        <div className="z-0 absolute left-0 opacity-55 right-0 top-0 bottom-0 bg-black"></div>
        <h1 className="z-10 text-xl font-mono tracking-wider  from-emerald-300 to-emerald-700 bg-gradient-to-tr bg-clip-text text-transparent first-letter:uppercase">
          {props.msg.message}
        </h1>
      </div>
    );
  }

  return (
    <div
    id={props.msg._id}
      className={`chat break-words max-w-full overflow-hidden ${
        props.isMe ? "chat-end" : "chat-start"
      } snap-start `}
    >
      <div className=" chat-image">
        <div
          className={`rounded-full ${sideColor} w-7 h-7 sm:w-10 sm:h-10 mr-0`}
        >
          {participantIcons[props.side]}
        </div>
      </div>
      <div className="chat-header text-slate-300">
        {props.msg.displayName || ""}
      </div>

      <div
        className={`${
          props.ext?.type === "guided" && `ring-2 ${guidColors}`
        } chat-bubble min-w-14 relative  ring-black border-white ${
          isMobile ? "" : "max-w-[50%]"
        } shadow-xl rounded-md  text-white`}
      >
        <div
          className={` absolute ${
            props.isMe
              ? "translate-x-[-105%] left-0 "
              : "translate-x-[105%] right-0"
          } top-0`}
        >
          {props.ext?.type !== "guided" && (
            <div
              className={`flex ${
                props.isMe ? "flex-row-reverse" : "flex-row"
              } gap-2 w-fit overflow-visible`}
            >
              <MessageBtns
                ILiked={props.ILiked}
                sendLike={sendLike}
                withEdit={props.isMe}
                openEdit={onOpenEdit}
              />
              <div className="join join-vertical items-end">
                <LikeView like={props.likeA} color="text-green-500" />
                <LikeView like={props.likeB} color="text-red-500" />
              </div>
            </div>
          )}
        </div>
        {props.ext?.type === "guided" ? (
          <>
            <h1
              className={`text-2xl ${textGuidedColors} first-letter:uppercase`}
            >
              {props.ext.stage}:
            </h1>
            <p className="text-lg">{props.msg.message}</p>
          </>
        ) : (
          <p>{props.msg.message}</p>
        )}
      </div>

      <time className="chat-footer opacity-50 text-slate-300">
        {time.toLocaleTimeString()}
      </time>
    </div>
  );
});

export default Message;

interface MessageBtnsProps {
  withEdit: boolean;
  sendLike: (like: boolean) => void;
  ILiked?: boolean;
  openEdit?: () => void;
}

const MessageBtns = (props: MessageBtnsProps) => {
  const [toView, setToView] = useState(false);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!toView) return;
    const handleClick = (e: MouseEvent) => {
      if (
        buttonsRef.current &&
        !buttonsRef.current.contains(e.target as Node)
      ) {
        setToView(false);
      }
    };
    document.addEventListener("click", handleClick);
    keepView();
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [toView]);

  const keepView = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setToView(false);
    }, 5000);
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {toView ? (
          <motion.div
            onMouseEnter={keepView}
            ref={buttonsRef}
            key="buttons"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="w-fit h-full  "
          >
            <div className="join join-horizontal">
              {props.ILiked ? (
                <button
                  className="btn join-item"
                  onClick={() => props.sendLike(false)}
                >
                  <SlDislike className="w-5 h-5" />
                </button>
              ) : (
                <button
                  className="btn join-item"
                  onClick={() => props.sendLike(true)}
                >
                  <SlLike className={`w-5 h-5 `} />
                </button>
              )}

              {props.withEdit && (
                <button className="btn join-item" onClick={props.openEdit}>
                  <CiEdit className="w-5 h-5" />
                </button>
              )}
            </div>
          </motion.div>
        ) : (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="activate"
            className="btn btn-ghost p-0 m-0 "
            onClick={() => {
              setToView(true);
            }}
          >
            <BiDotsVertical className="w-5 h-5 opacity-50" />
          </motion.button>
        )}
      </AnimatePresence>
    </>
  );
};

const LikeView = ({ like, color }: { like?: number; color: string }) => {
  return (
    <AnimatePresence mode="sync">
      {like && like > 0 ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          key="like-container"
          className="join-item flex gap-1 items-end"
        >
          <CiHeart className={`w-full h-full min-w-4 ${color} p-0`} />
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: 10 }}
            key={like} // Use the `like` value as the key to trigger animation when it changes
            className="text-xs md:text-sm"
          >
            {like}
          </motion.div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="empty-state"
        />
      )}
    </AnimatePresence>
  );
};
