import { CaseReturnType } from "types/CaseTypes";
import { Participant } from "types/userTypes";
import GuidedNegotiation from "../chat/GuidedNegotiation/GuidedNegotiation";
import CaseInfo from "../../components/chat/CaseInfo";
import ParticipantView from "../chat/ParticipantView";
import RecommendationView from "../chat/RecommendationView";
import SummaryStage from "../chat/Summary";
import Note from "../chat/Note";
import useGuidedChat from "hooks/useGuidedChat";

interface InfoSectionProps {
  role: string;
  participants: Map<string, Participant>;
  caseInfo?: CaseReturnType;
  selectedInfoIdx: number;
  handleSelectInfo: (idx: number) => void;
  handleSendSurvey: () => void;
}

const InfoSectionNew = (props: InfoSectionProps) => {
  const GuidChat = useGuidedChat();

  const handleSummary = () => {
    props.handleSelectInfo(3);
  };

  return (
    <div
      className={`z-2   w-full  grid  h-full  pb-8   divide-y-2 divide-slate-600 bg-slate-900  divide-dotted shrink-0   grid-rows-3  gap-2`}
    >
      {props.selectedInfoIdx === 0 || props.role === "User" ? (
        <>
          <div className="row-span-2">
            <CaseInfo role={props.role} handleSendSurvey={props.handleSendSurvey} caseInfo={props.caseInfo} />
          </div>

        </>
      ) : props.selectedInfoIdx === 1 ? (
        <div className="w-full row-span-2 ">
          <div className="w-full  block h-full z-50 overflow-x-hidden ">
            <RecommendationView />
          </div>
        </div>
      ) : props.selectedInfoIdx === 2 ? (
        <div className="w-full row-span-2 ">
          <GuidedNegotiation
            stage={GuidChat.stage}
            iteration={GuidChat.iteration}
            handleChangeStage={GuidChat.handleChangeStage}
            handleNextIteration={GuidChat.handleNextIteration}
            handleContinueAnyway={GuidChat.handleContinueAnyway}
            greetingsMsg={GuidChat.greetingsMsg}
            onGreetingsChange={GuidChat.onGreetingsChange}
            onInitChange={GuidChat.onInitChange}
            handleEndNegotiation={GuidChat.handleEndNegotiation}
            initData={GuidChat.initData}
            handleSummary={handleSummary}
            handleStartNegotiation={GuidChat.handleStartNegotiation}
            initialReceived={GuidChat.initialReceived}
            handleAddIteration={GuidChat.handleAddIteration}
            sendInitialPositionAction={GuidChat.sendInitialPositionAction}
          />
        </div>
      ) : props.selectedInfoIdx === 3 ? (
        <div className=" w-full min-h-full  overflow-y-scroll row-span-3 max-h-full   block h-full z-50 overflow-x-hidden">
          <SummaryStage />
        </div>
      ) : (
        <div className=" w-full min-h-full  overflow-y-scroll row-span-2 max-h-full  block h-full z-50 overflow-x-hidden">
          <Note />
        </div>
      )}

      {props.selectedInfoIdx !== 3 && (
        <footer className="flex flex-col w-full h-full row-span-2 ">
          <ParticipantView participants={props.participants} />
        </footer>
      )}
    </div>
  );
};
export default InfoSectionNew;
