import { GroupType, MessageType } from "types/messageType";
import InputBar from "../InputBar/inputBarNew";
import MessagesBoard from "./MessageBoard";
import { Participant } from "types/userTypes";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import InfoBar from "../InfoBar/infoBar";
import NotificationViewer from "../alert/NotificationViewer";

interface ChatSectionProps {
  role: string;
  handleSendMessage: (msg: string) => void;
  selectedGroup: GroupType;
  isShuttle: boolean;
  handleShuttle: () => void;
  participants: Map<string, Participant>;
  side: "A" | "B" | "M";
  case_category: string;
}

const ChatSection = (props: ChatSectionProps) => {
  const [scrollYProgress, setScrollYProgress] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [potentialMessages, setPotentialMessages] = useState<MessageType[]>([]);
  const [searchIndex, setSearchIndex] = useState<number>(-1);
  const [initialStateOpened, setInitialStateOpened] = useState<boolean>(false);
  const [actionMove, setActionMove] = useState<boolean>(false);

  const handleScroll = (progress: number) => {
    setScrollYProgress(progress);
  };

  return (
    <div className="divide-y flex flex-col  overflow-hidden   divide-stone-600 w-full h-full    ">
      <header className="w-full h-16 flex  bg-slate-900  overflow-y-hidden">
        <InfoBar
          setSearchIndex={setSearchIndex}
          searchIndex={searchIndex}
          potentialMessagesLength={potentialMessages.length}
          setSearchTerm={setSearchTerm}
          isShuttle={props.isShuttle}
          side={props.side}
          case_category={props.case_category}
        />
        <NotificationViewer
          setInitialStateOpened={setInitialStateOpened}
          setActionMove={setActionMove}
          actionMove={actionMove}
          isShuttle={props.isShuttle}
        />
      </header>

      <section className="w-full h-full  overflow-hidden  ">
        <MessagesBoard
          actionMove={actionMove}
          initialStateOpened={initialStateOpened}
          setInitialStateOpened={setInitialStateOpened}
          setActionMove={setActionMove}
          potentialMessages={potentialMessages}
          searchIndex={searchIndex}
          setPotentialMessages={setPotentialMessages}
          setSearchIndex={setSearchIndex}
          searchTerm={searchTerm}
          handleScroll={handleScroll}
          participants={props.participants}
          selectedGroup={props.selectedGroup}
        />
      </section>

      <motion.div
        className="progress bg-gradient-to-r from-emerald-300 to-emerald-500 w-full h-2"
        style={{ scaleX: scrollYProgress }}
      />

      <footer className="w-full h-20 flex py-2 pl-1 pr-2 items-center justify-center">
        <InputBar
          role={props.role}
          handleShuttle={props.handleShuttle}
          isShuttle={props.isShuttle}
          handleSendMessage={props.handleSendMessage}
          selectedGroup={props.selectedGroup}
        />
      </footer>
    </div>
  );
};

export default ChatSection;
