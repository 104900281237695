import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useEffect } from "react";

interface BottomDrawerProps {
  children?: React.ReactNode;
  trigger?: React.ReactNode;
  drawerTitle?: string;
  drawerDescription?: string;
  drawerFooter?: React.ReactNode;
  onSubmit?: () => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const BottomDrawer = (props: BottomDrawerProps) => {
  return (
    <Drawer open={props.open} onOpenChange={props.onOpenChange}>
      <DrawerTrigger asChild>{props.trigger}</DrawerTrigger>
      <DrawerContent className="bg-slate-800 text-white">
        <DrawerHeader>
          <DrawerTitle className="text-center">{props.drawerTitle}</DrawerTitle>
          <DrawerClose />
        </DrawerHeader>
        {props.children}
        <DrawerDescription className="text-center">
          {props.drawerDescription
            ? props.drawerDescription
            : "Please provide your party initial state"}
        </DrawerDescription>
        <DrawerFooter>
          <DrawerClose asChild>{props.drawerFooter}</DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
export default BottomDrawer;
