import { useEffect, useRef } from "react";
import useChatSocket from "./useChatSocket";
import {
  ActionMsg,
  ActionPayloads,
  CaseDataRedis,
  Emits,
  guidType,
} from "types/CaseTypes";
import { LikeFullReturnType, LikeType, MessageType } from "types/messageType";
import { useAppDispatch, useAppSelector } from "./storHook";
import {
  addAction,
  addInitialPosition,
  addLikes,
  addMessage,
  clearAction,
  initialMessages,
  setActions,
  setNotification,
  setShuttle,
  removeLike,
  editMsg,
  addNotification,
} from "store";
import { IGuid, setGuidedData } from "store/reducers/GuidSlice";
import { getRole } from "../utils/utilsFunction";

interface ChatProps {
  selectedGroup: "M" | "B" | "A";
  case_id: any;
}

const useChat = ({ selectedGroup, case_id }: ChatProps) => {
  const { on, off, ons, offAllListeners, socket } = useChatSocket();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const role = getRole(user);
  const selectedGroupRef = useRef(selectedGroup);

  useEffect(() => {
    if (!socket) return;

    if (role === "Mediator") {
      ons([
        {
          //   [Emits.startCase]: handleStartChatMediator,
          [Emits.guid]: handleGuidMediator,
        },
      ]);
    }
    if (role === "User") {
      ons([
        {
          [Emits.sendSurvey]: handleGetSurvey,
        },
      ]);
    }

    //Adding all users listeners
    ons([
      {
        [Emits.roomMsg]: handleRoomMessage,
        [Emits.history]: handleHistoryMessages,
        [Emits.shuttle]: onShuttle,
        // [Emits.userJoin]: handleJoin,
        // [Emits.userLeave]: handleLeave,
        [Emits.action]: handleActionMsg,
        [Emits.historyActions]: handleHistoryActions,
        [Emits.like]: addLike,
        [Emits.removeLike]: removeLikeFunc,
        [Emits.editMsg]: editMsgFunc,
      },
    ]);

    return () => {
      console.log('off all listeners')
      offAllListeners();
    };
  }, [socket]);

  useEffect(() => {
    selectedGroupRef.current = selectedGroup;
  }, [selectedGroup]);

  const handleGetSurvey = () => {
    dispatch(addNotification("Please fill out the survey"));
    dispatch(addAction("fillSurvey"));
  };

  const editMsgFunc = (data: {
    message_id: string;
    case_id: string;
    side: string;
    message: string;
  }) => {
    dispatch(editMsg(data));
  };

  // Handle room message
  // Notify if not the current group dispatch to chat slice
  // Add message to the message slice
  const handleRoomMessage = (msg: MessageType) => {
    if (msg.side !== selectedGroupRef.current) {
      dispatch(setNotification(msg.side)); // Notify if not the current group
    }
    dispatch(addMessage(msg)); // Add message to the store
  };

  const removeLikeFunc = (data: LikeFullReturnType) => {
    const isMe = data.user_id.toString() === user.id.toString();

    const { message_id, user_id, side, displayName } = data;
    dispatch(removeLike({ message_id, user_id, side, isMe }));
  };

  const addLike = (data: LikeFullReturnType | LikeFullReturnType[]) => {
    const dispatchData = Array.isArray(data) ? data : [data];

    //check if data is an array
    if (!Array.isArray(data)) {
      const isMe = data.user_id === user.id.toString();
      if (!isMe) {
        dispatch(addNotification(`${data.displayName} like message`));
      }
    }

    const convertedData = dispatchData.map((like) => {
      const isMe = like.user_id === user.id.toString();
      const likeData: {
        message_id: string;
        isMe: boolean;
        side: string;
        displayName: string;
        user_id: string;
        like: string;
      } = {
        message_id: like.message_id,
        side: like.side,
        displayName: like.displayName,
        user_id: like.user_id,
        like: like.like,
        isMe,
      };
      return likeData;
    });
    dispatch(addLikes(convertedData));
  };

  //dispatch to message slice the initial messages
  const handleHistoryMessages = (msgs: MessageType[]) =>
    dispatch(initialMessages(msgs));

  //dispatch to chat slice the shuttle status
  const onShuttle = (shuttleData: CaseDataRedis) =>
    dispatch(setShuttle(shuttleData.shuttle));

  const handleHistoryActions = (actions: string[]) => {
    if (actions.length === 0) return;
    if (actions.includes(ActionPayloads.initialPosition)) {
      if (
        (user.side === "A" &&
          actions.includes(ActionPayloads.receivedInitialA)) ||
        (user.side === "B" && actions.includes(ActionPayloads.receivedInitialB))
      ) {
        actions = actions.filter(
          (action) => action !== ActionPayloads.initialPosition
        );
      }
    }
    dispatch(setActions(actions));
  };
  const handleActionMsg = (actionMsg: ActionMsg) => {
    const { actionType } = actionMsg;
    if (actionType === "add") {
      handleAddAction(actionMsg);
    } else if (actionType === "remove") {
      handleRemoveAction(actionMsg);
    }
  };

  const handleAddAction = (actionMsg: ActionMsg) => {
    const { actionPayload } = actionMsg;

    if (role === "Mediator") {
      if (actionPayload === ActionPayloads.receivedInitialA) {
        const msg = actionMsg.extMsg;
        dispatch(addInitialPosition({ A: msg }));
        dispatch(addNotification("Initial Position Received from A"));
      } else if (actionPayload === ActionPayloads.receivedInitialB) {
        const msg = actionMsg.extMsg;
        dispatch(addInitialPosition({ B: msg }));
        dispatch(addNotification("Initial Position Received from B"));
      }
    }
    dispatch(addAction(actionMsg.actionPayload));
  };

  const handleRemoveAction = (actionMsg: ActionMsg) => {
    const { actionPayload, from } = actionMsg;
    if (from === user.id && actionPayload === ActionPayloads.initialPosition)
      return;
    dispatch(clearAction(actionPayload));
  };

  const handleGuidMediator = (guid: guidType) => {
    const {
      stageIdx,
      initialIteration,
      iteration,
      receivedInitialA,
      receivedInitialB,
    } = guid;

    const guidData: Partial<IGuid> = {
      stage: Number(stageIdx),
      iteration: Number(iteration),
      initialIteration: Number(initialIteration),
      hasStarted: stageIdx > 0,
      initialPosition: { A: receivedInitialA, B: receivedInitialB },
    };
    dispatch(setGuidedData(guidData));
  };
  return {
    on,
    ons,
    off,
    offAllListeners,
    socket,
  };
};

export default useChat;
