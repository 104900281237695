import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector, useAppDispatch } from "hooks/storHook";
import { useEffect } from "react";
import { removeNotification } from "store";
import { MdOutlineTouchApp } from "react-icons/md";

const NotificationViewer = ({
  isShuttle,
  actionMove,
  setActionMove,
  setInitialStateOpened,
}: {
  isShuttle: boolean;
  actionMove: boolean;
  setInitialStateOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setActionMove: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const notification = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!notification.length) return;
    setTimeout(() => {
      dispatch(removeNotification());
    }, 3000);
  }, [notification]);

  const handleActionModeClicked = () => {
    setInitialStateOpened(true);
    setActionMove(false);
  };

  return (
    <AnimatePresence mode="wait">
      {actionMove ? (
        <motion.div
          onClick={handleActionModeClicked}
          key={11}
          initial={{ y: "100%", opacity: 0 }}
          animate={{ y: "0%", opacity: 0.6 }}
          exit={{ y: "100%", opacity: 0 }}
          className="w-full mt-2 px-5 flex justify-center cursor-pointer   items-center bg-rose-500 "
        >
          <p className="text-white text-xs xl:text-lg ">Click To Set Initial State</p>
          <MdOutlineTouchApp className="text-white ml-2 text-2xl" />
        </motion.div>
      ) : !isShuttle && !notification.length ? (
        <></>
      ) : notification[0] ? (
        <motion.div
          key={11}
          initial={{ y: "100%", opacity: 0 }}
          animate={{ y: "0%", opacity: 0.6 }}
          exit={{ y: "100%", opacity: 0 }}
          className="w-full mt-2 px-5 flex justify-center   items-center bg-black "
        >
          <p className="text-white text-xs xl:text-lg">{notification[0]}</p>
        </motion.div>
      ) : (
        <motion.div
          key={12}
          initial={{ y: "100%", opacity: 0 }}
          animate={{ y: "0%", opacity: 0.6 }}
          exit={{ y: "100%", opacity: 0 }}
          className="w-full mt-2 px-5 flex justify-center   items-center bg-black "
        >
          <p className="text-white text-xs xl:text-lg">Shuttle mode activated</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NotificationViewer;
