import { TbLetterA, TbLetterB, TbLetterM } from "react-icons/tb";
import { PiChatCenteredSlash } from "react-icons/pi";
import { MdOutlineNotifications } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { MdFamilyRestroom } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { TbSchool } from "react-icons/tb";
import { VscOrganization } from "react-icons/vsc";
import { MdBusinessCenter } from "react-icons/md";
import { LiaUniversitySolid } from "react-icons/lia";
import { LuBriefcase } from "react-icons/lu";
import { PiChatCenteredTextLight } from "react-icons/pi";
import { Separator } from "@/components/ui/separator";
import { CiSearch } from "react-icons/ci";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { useAppSelector } from "hooks/storHook";
import { AnimatePresence, motion } from "framer-motion";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdClose } from "react-icons/md";

interface InfoBarProps {
  side: "A" | "B" | "M";
  case_category: string;
  isShuttle: boolean;
  setSearchTerm: (term: string) => void;
  potentialMessagesLength: number;
  searchIndex: number;
  setSearchIndex: React.Dispatch<React.SetStateAction<number>>;
}

const sidesObject = {
  A: <TbLetterA className="  w-full text-2xl  " />,
  B: <TbLetterB className="  w-full text-2xl  " />,
  M: <TbLetterM className="  w-full text-2xl  " />,
};

const categoriesObject = {
  Family: <MdFamilyRestroom className="  w-full text-2xl  " />,
  Friends: <FaUserFriends className="  w-full text-2xl  " />,
  Neighbors: <FaUserFriends className="  w-full text-2xl  " />,
  Education: <TbSchool className="  w-full text-2xl  " />,
  Organization: <VscOrganization className="  w-full text-2xl  " />,
  Business: <MdBusinessCenter className="  w-full text-2xl  " />,
  Politics: <LiaUniversitySolid className="  w-full text-2xl  " />,
  Other: <LuBriefcase className="  w-full text-2xl  " />,
};

const InfoBar = (props: InfoBarProps) => {
  const notifications = useAppSelector((state) => state.notification);
  const [openedSearch, setOpenedSearch] = useState<boolean>(false);
  const sideIcon = useMemo(() => {
    return sidesObject[props.side || "M"];
  }, [props.side]);

  const categoryIcon = useMemo(() => {
    return categoriesObject[
      (props.case_category as keyof typeof categoriesObject) || "Other"
    ];
  }, [props.case_category]);

  useEffect(() => {
    if (!openedSearch) props.setSearchTerm("");
  }, [openedSearch]);

  const moveToNextMsg = (interval: 1 | -1) => {
    if (props.potentialMessagesLength === 0) return;
    props.setSearchIndex((prev) => prev + interval);
  };

  return (
    <div className="grid h-full  grid-cols-5 relative max-w-48 min-w-48  sm:max-w-64 sm:min-w-60 bg-[#414a4f]  text-black  rounded-tr-xl ">
      <div id="info-bar-side" className="w-full h-16 flex items-center  ">
        {sideIcon}
        <Separator orientation="vertical" className="h-1/2 bg-black" />
      </div>

      <div className="w-full h-16 flex items-center  ">
        {categoryIcon}
        <Separator orientation="vertical" className="h-1/2 bg-black" />
      </div>

      <div className="w-full h-16 flex items-center  ">
        {props.isShuttle ? (
          <PiChatCenteredSlash className="  w-full text-2xl text-rose-500 " />
        ) : (
          <PiChatCenteredTextLight className="  w-full text-2xl   " />
        )}
        <Separator orientation="vertical" className="h-1/2 bg-black " />
      </div>

      <div className="w-full h-16 flex items-center  ">
        {notifications.length > 0 ? (
          <MdOutlineNotificationsActive className=" w-full text-2xl text-amber-500  " />
        ) : (
          <MdOutlineNotifications className=" w-full text-2xl  " />
        )}

        <Separator orientation="vertical" className="h-1/2 bg-black" />
      </div>
      <div
        onClick={() => setOpenedSearch(true)}
        className={`w-full h-16 flex items-center active:scale-95 z-[2] cursor-pointer `}
      >
        <CiSearch className="  w-full text-2xl " />
      </div>
      <AnimatePresence>
        {openedSearch && (
          <motion.div
            key={1}
            initial={{ opacity: 0, x: "-10%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "-10%" }}
            className="z-10 absolute left-0 top-0 w-full h-full flex justify-between  "
          >
            <button onClick={()=>setOpenedSearch(false)} className="absolute z-10 left-0 top-1/2 translate-y-[-50%]">
              <MdClose className="w-6 h-6 text-black" />
            </button>
            <input
              type="text"
              className="w-4/5 h-full  px-4 pl-10 text-black bg-slate-300 "
              placeholder="Search"
              onChange={(e) => props.setSearchTerm(e.target.value)}
            />

            {props.potentialMessagesLength > 0 && (
              <motion.div
                key={2}
                initial={{ opacity: 0, x: "50%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "50%" }}
                className=" join join-vertical z-20 h-full bg-slate-500 grow rounded-none "
              >
                <button
                  onClick={() => moveToNextMsg(-1)}
                  disabled={props.searchIndex === 0}
                  className={` join-item ${
                    props.searchIndex > 0 &&
                    "active:scale-95 transition-all duration-100 ease-in-out"
                  }  h-1/2 grow   `}
                >
                  <FaChevronUp
                    className={`w-full h-full ${
                      props.searchIndex > 0 ? "text-white" : "text-slate-600"
                    }  border-b-[1px] border-black`}
                  />
                </button>
                <button
                  disabled={
                    props.searchIndex === props.potentialMessagesLength - 1
                  }
                  onClick={() => moveToNextMsg(1)}
                  className={` join-item  h-1/2  grow ${
                    props.searchIndex < props.potentialMessagesLength - 1 &&
                    "active:scale-95 transition-all duration-100 ease-in-out"
                  } border-t-[1px] border-black `}
                >
                  <FaChevronDown
                    className={`w-full h-full ${
                      props.searchIndex < props.potentialMessagesLength - 1
                        ? "text-white"
                        : "text-slate-600"
                    }`}
                  />
                </button>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default InfoBar;
