import { TabsFAQ } from "pages/mediatorPage/dashboard";
import LoginPage from "pages/login/LoginPage";
import "./App.css";
import ChatPage from "pages/chat/ChatPage";
import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "./utils/RequireAuth";
import LoadingPage from "pages/loadingPage/loadingPage";
import AddCaseView from "pages/mediatorPage/AddCase";
import ClientLogin from "pages/login/clientLogin";
import { Toaster } from "@/components/ui/toaster";
import Logout from "pages/login/logout";
import InfoPage from "pages/infoPage/infoPage";
import StatusPage from "pages/statusPage/statusPage";
import AdminPage from "pages/AdminPage/AdminPage";
import SettingPage from "pages/setting/settingPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/client-login" element={<ClientLogin />} />
        <Route path="/logout" element={<Logout />} />

        <Route
          path="/unauthorized"
          element={<StatusPage status="Unauthorized" />}
        />

        <Route path="/" element={<RequireAuth />}>

          <Route path="/User">
            <Route path="dashboard" element={<ChatPage />} />
          </Route>
          <Route path="/Admin">
            <Route path="dashboard" element={<AdminPage />} />
          </Route>
          <Route path="/Mediator">
            <Route path="case/:id" element={<InfoPage />} />
            <Route path="settings" element={<SettingPage />} />

            <Route path="dashboard" element={<TabsFAQ />} />
            <Route path="new-case" element={<AddCaseView />} />
            <Route path="chat" element={<ChatPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>

        {/* Catch-all route for unmatched paths */}
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
