import Headline from "../../components/background/headline";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import MenuLinks from "../../components/menu/menuLinks";
import NavBar from "../../components/navBar/navBar";
import { useAppSelector } from "hooks/storHook";

const SettingPage = () => {
  const [menuPageIdx, setMenuPageIdx] = useState<number>(-1);

  const setPage = (idx: number) => {
    setMenuPageIdx(idx);
  }
  return (
    <>
     <NavBar/>
    <section className="w-full flex flex-col h-[100dvh] overflow-hidden bg-[#414a4f] px-4 py-12 text-slate-50">
      <Headline text="Settings" />
      <div className=" w-full h-full  mx-auto max-w-3xl ">
        <AnimatePresence mode="sync">
          <MenuLinks
          key={1}
          links={['Profile', 'Account', 'Security', 'Notifications']}
            menuPageIdx={menuPageIdx}
            setMenuPageIdx={setPage}
          />

          {menuPageIdx === 0 && (
            <ProfileView key={2}/>
          )}
          
        </AnimatePresence>
      </div>
    </section>
    </>
  );
};
export default SettingPage;



const ProfileView = ()=>{
  const user = useAppSelector((state) => state.user);

  console.log(user)

  return <>
  Profile
  </>
  
}
